import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "framework7-react";
import { t } from "i18next";

const InactivityProvider = ({ children }) => {
	const refreshRate = 1800;
	const focusMargin = 50;

	const [lastUserAction, setLastUserAction] = useState(0);
	const [hasFocus, setHasFocus] = useState(false);
    const [lostFocusCount, setLostFocusCount] = useState(0);
    
    const reloadMessage = useRef(null);

	const reset = useCallback(() => {
		setLastUserAction(0);
	}, []);

	const windowHasFocus = useCallback(() => {
		setHasFocus(true);
	}, []);

	const windowLostFocus = useCallback(() => {
		setHasFocus(false);
		setLostFocusCount((prevCount) => {
			const newCount = prevCount + 1;
			return newCount;
		});
	}, []);

	const checkWindowFocus = useCallback(() => {
		document.visibilityState === "visible"
			? windowHasFocus()
			: windowLostFocus();
	}, [windowHasFocus, windowLostFocus]);

	const refreshCheck = useCallback(() => {
		if (
			(lastUserAction >= refreshRate &&
				!hasFocus &&
				document.readyState === "complete") ||
			lostFocusCount > focusMargin
        ) {
            reloadMessage.current.classList.remove("hidden");
			window.location.reload(true);
			reset();
		}
	}, [lastUserAction, hasFocus, lostFocusCount, reset]);

	useEffect(() => {
		const interval = setInterval(() => {
			setLastUserAction((prev) => prev + 1);
			refreshCheck();
		}, 1000);

		return () => clearInterval(interval);
	}, [refreshCheck]);

	useEffect(() => {
		const eventListeners = [
			{ type: "focus", handler: windowHasFocus },
			{ type: "blur", handler: windowLostFocus },
			// { type: "visibilitychange", handler: checkWindowFocus },
			{ type: "click", handler: reset },
			{ type: "mousemove", handler: reset },
			{ type: "keypress", handler: reset },
			{ type: "scroll", handler: reset },
			{ type: "touchmove", handler: reset },
			{ type: "touchend", handler: reset },
		];

		eventListeners.forEach(({ type, handler }) =>
			window.addEventListener(type, handler)
		);

		return () => {
			eventListeners.forEach(({ type, handler }) =>
				window.removeEventListener(type, handler)
			);
		};
	}, [windowHasFocus, windowLostFocus, reset]);

	return (
		<>
			<div
				ref={reloadMessage}
				id="inactivityScreen"
				className="fixed top-0 left-0 bottom-0 right-0 bg-white/30 z-[99999] backdrop-blur flex flex-col gap-5 justify-center items-center hidden transition-all duration-500 ease-in-out">
				<span className="text-primary text-2xl text-center font-gelar">
					KT Messenger Web
				</span>
				<span className="text-center tracking-wide">
					{t(
						"You have been inactive for a while. Please reload the app if it doesn't reload automatically to keep chatting."
					)}
				</span>
				<Button
					fill
					rounded
					small
					className="text-lg px-5"
					onClick={() => {
						window.location.reload(true);
						reset();
					}}>
					Reload
				</Button>
			</div>
			{children}
		</>
	);
};

export default InactivityProvider;
