import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { unixTimestampToDate } from "../../../../utils/functions";
import {
	Block,
	Button,
	Link,
	List,
	ListItem,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	PageContent,
	Searchbar,
	Subnavbar,
	f7,
	useStore,
} from "framework7-react";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { Calendar } from "iconsax-react";
import "./style.css";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import { useSelector } from "react-redux";

const SearchPanel = ({ chat_id }) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const { t, i18n } = useTranslation();
	const searchbar = useRef(null);
	const datepicker = useRef(null);
	const dateInput = useRef(null);
	const [searchTerm, setSearchTerm] = useState("");
	const { loginResponse } = useSelector((state) => state.loginSlice);
	useEffect(() => {
		// searchbar.current && searchbar.current.destroy();
		datepicker.current && datepicker.current.destroy();
		setSearchTerm("");
	}, [chat_id]);
	const chat = useLiveQuery(async () => {
		if (!chat_id || chat_id === "null" || chat_id === NaN) return null;
		return await db.chats.get(chat_id);
	}, [chat_id]);
	const [searchResult, setSearchResult] = useState([]);
	const translatedMessages = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || chat_id === NaN) return [];
			return await db.translatedMessages
				.where("chat_id")
				.equals(chat_id)
				.filter(function (message) {
					return searchTerm?.length >= 1
						? message.type === "text"
							? new RegExp(searchTerm.toLowerCase()).test(
									message.message.toLowerCase()
							  ) ||
							  new RegExp(searchTerm.toLowerCase()).test(
									message.original_message.toLowerCase()
							  )
							: new RegExp(searchTerm.toLowerCase()).test(
									message.caption.toLowerCase()
							  ) ||
							  new RegExp(searchTerm.toLowerCase()).test(
									message.original_caption.toLowerCase()
							  )
						: false;
				})
				.toArray();
		},
		[searchTerm, chat_id],
		[]
	);
	const messages = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || chat_id === NaN) return [];
			return await db.messages
				.where("chat_id")
				.equals(chat_id)
				.filter(function (message) {
					return searchTerm?.length >= 1
						? message.type === "text"
							? new RegExp(searchTerm.toLowerCase()).test(
									message.message.toLowerCase()
							  ) ||
							  new RegExp(searchTerm.toLowerCase()).test(
									message.original_message.toLowerCase()
							  )
							: new RegExp(searchTerm.toLowerCase()).test(
									message.caption.toLowerCase()
							  ) ||
							  new RegExp(searchTerm.toLowerCase()).test(
									message.original_caption.toLowerCase()
							  )
						: false;
				})
				.toArray();
		},
		[searchTerm, chat_id],
		[]
	);
	const oldestMessage = useLiveQuery(
		() => db.messages.orderBy("unix_time").first(),
		[],
		[]
	);

	useLayoutEffect(() => {
		const mergedArray = [
			...translatedMessages,
			...messages.filter(
				(msg) => !translatedMessages.some((tmsg) => tmsg.id === msg.id)
			),
		].sort((a, b) => b.unix_time - a.unix_time);
		setSearchResult(mergedArray);
	}, [translatedMessages, messages]);

	const openDatePicker = () => {
		if (!datepicker.current) {
			datepicker.current = f7.calendar.create({
				inputEl: dateInput?.current,
				value: new Date(),
				maxDate: new Date(),
				openIn: "popover",
				closeOnSelect: true,
				cssClass: "calendar-message-search",
				on: {
					dayClick(c, dayEl, year, month, day) {
						const date = unixTimestampToDate(new Date(year, month, day));
						f7.emit("searchMessage", date.replace(/[\s,]+/g, "_"));
					},
				},
			});
		}
		datepicker.current.open();
	};

	const searchMessage = (e) => {
		e.preventDefault();
		let replasestr = e.target.value.replace(/@/g, "");
		setSearchTerm(replasestr);
	};

	const HighlightText = ({ message }) => {
		const text =
			message.type === "text"
				? message.message &&
				  new RegExp(searchTerm.toLowerCase()).test(
						message.message.toLowerCase()
				  )
					? message.message
					: message.original_message || ""
				: message.caption &&
				  new RegExp(searchTerm.toLowerCase()).test(
						message.caption.toLowerCase()
				  ) &&
				  message.caption
				? message.caption
				: message.original_caption || "";

		const regex = /\[([^\]]+)\]\((\d+)\)/g;
		const replacedText = text.replace(regex, (match, p1) => {
			return p1; // Return only the captured text inside @[text]
		});

		const parts = replacedText.split(new RegExp(`(${searchTerm})`, "gi"));
		const highlightedText = parts.map((part, index) => {
			if (part.toLowerCase() === searchTerm.toLowerCase()) {
				return (
					<span key={index} className="text-kt-blue">
						{part}
					</span>
				);
			}
			return part;
		});

		return <>{highlightedText}</>;
	};

	return (
		<Page id="searchMessagePage" pageContent={false}>
			<Navbar>
				<NavLeft>
					<Link panelClose>
						<MaterialIcon size={48} icon="close" />
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Search Messages")}
				</NavTitle>
			</Navbar>
			<Subnavbar>
				<Button className="h-[40px]" onClick={openDatePicker}>
					<Calendar size="24" color="#302f36" />
				</Button>
				<Searchbar
					ref={searchbar}
					inline
					backdrop={false}
					disableButton={false}
					inner={false}
					placeholder={t("Search Phrase")}
					className="w-full"
					onChange={searchMessage}
					onSearchbarClear={() => setSearchTerm("")}
				/>
				<input ref={dateInput} type="hidden" />
			</Subnavbar>
			<PageContent className="search-content">
				{searchTerm.length >= 1 && (
					<List dividers>
						{searchResult?.length > 0 ? (
							searchResult.map((message) => (
								<ListItem
									key={message.id}
									header={unixTimestampToDate(message.unix_time)}
									className="cursor-pointer"
									onClick={() => {
										f7.emit("searchMessage", message.id);
									}}>
									<div
										slot="title"
										className="text-ellipsis overflow-hidden whitespace-nowrap">
										<span className="pr-1 font-semibold">
											{message.sender_id === loginResponse.data.id
												? "You"
												: message.sender_name}
											:
										</span>
										<HighlightText message={message} />
									</div>
								</ListItem>
							))
						) : (
							<ListItem title={t("No messages found")} />
						)}
					</List>
				)}
				<Block>
					{searchTerm.length < 1
						? t("Search messages {{type}} {{name}}", {
								type: chat?.type === "single" ? "with" : "within",
								name: chat?.nickname || chat?.firstname,
						  })
						: t(
								"Use {{name}} on your phone to search messages from before {{date}}",
								{
									name: f7.name,
									date: unixTimestampToDate(oldestMessage?.unix_time),
								}
						  )}
				</Block>
			</PageContent>
		</Page>
	);
};

export default SearchPanel;
