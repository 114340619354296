import { useLiveQuery } from "dexie-react-hooks";
import { useStore } from "framework7-react";
import React from "react";
import { db } from "../../../js/db";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";

const ChatMediaWidget = ({ chat_id, onClick = () => {} }) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}

	const media = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || chat_id === NaN) return [];
			let data = await db.messages
				.where({ chat_id: chat_id, type: "image" })
				.toArray();
			data.sort((a, b) => b.unix_time - a.unix_time);
			data = data.slice(0, 4);
			return data;
		},
		[chat_id],
		[]
	);
	return media && media?.length > 0 ? (
		<div className="flex items-center overflow-hidden w-full mt-4 gap-[10px]">
			{media?.map((item, index) => (
				<div key={index}>
					<LazyLoaderWraper
						src={item.thumbnail}
						placeholder={item.thumbnail}
						height={80}
						width={80}
						alt=""
						className="rounded-[10px] align-bottom cursor-pointer object-cover"
						wrapperclassname="rounded-[10px] align-bottom cursor-pointer"
						onClick={() => onClick(item.id)}
					/>
				</div>
			))}
		</div>
	) : null;
};

export default ChatMediaWidget;
