import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import {
	Button,
	NavLeft,
	NavRight,
	Navbar,
	Page,
	f7,
	useStore,
} from "framework7-react";
import MaterialIcon from "../../misc/materialIcon";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../js/db";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";
import message_icon from "../../../assets/images/icons/message-block.svg";
import userimage from "../../../assets/images/placeholders/user.png";
import groupimage from "../../../assets/images/placeholders/group.png";
import "./style.css";
import LightboxThumbnail from "../../misc/lightboxThumbnail";
import PdfViewer from "../../misc/PdfViewer";
import { getFileTypeByName, removeMD } from "../../../utils/functions";
import { useKey, useUpdateEffect, useWindowSize } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import { Swiper, SwiperSlide } from "swiper/react";
import DownloaderDialog from "../../misc/downloaderDialog";
import { ENDPOINTS } from "../../../constants/socket";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { SocketContext } from "../../../socket";
import MarkdownPreview from "@uiw/react-markdown-preview";
import rehypeSanitize from "rehype-sanitize";

const Lightbox = ({
	chat_id,
	selectedMessage,
	chatType,
	closePopup,
	opened,
	onDeleteMessage,
	onForwardPanel,
}) => {
	chat_id =
		chat_id !== "null"
			? chat_id !== "posh_ai"
				? parseInt(chat_id)
				: chat_id
			: chat_id;
	const { width } = useWindowSize();
	const isMobile = useMemo(() => width < 775, [width]);
	const selectedMesageId = useMemo(() => selectedMessage, [selectedMessage]);
	const [limit, setLimit] = useState(5);
	const [lightboxOpened, setLightboxOpened] = useState(opened);
	useKey("Escape", closePopup);
	const [swiper, setSwiper] = useState(null);
	const [fileToDownload, setFileToDownload] = useState(null);
	const [showDownloader, setShowDownloder] = useState(false);
	const socket = useContext(SocketContext);
	const [messageContext, setMessageContext] =
		useLocalStorageState("messageContext");
	const media = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === NaN || chat_id === "null") return [];

			let data = await db.messages
				.where({
					chat_id: chat_id,
				})
				.and((message) => {
					return (
						message.type === "image" ||
						message.type === "video" ||
						message.type === "mms"
					);
				})
				.reverse()
				.sortBy("unix_time");

			data = limit ? data.slice(0, limit) : data;

			// Expand MMS messages to include each file as a separate entry
			const expandedData = [];
			data.map((item) => {
				if (item.type === "mms") {
					try {
						const mms = JSON.parse(item.message);
						for (let i = mms.files.length - 1; i >= 0; i--) {
							expandedData.push({ ...item, mmsFile: mms.files[i] });
						}
					} catch (exp) {}
				} else {
					expandedData.push(item);
				}
			});

			return expandedData.reverse();
		},
		[limit, chat_id],
		[]
	);

	const [activeDocument, setActiveDocument] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);

	useLayoutEffect(() => {
		if (opened) {
			setTimeout(() => {
				setLimit(null);
			}, 5000);
		}
	}, [opened, limit]);

	useEffect(() => {
		if (opened && activeIndex !== 0) {
			swiper.slideTo(activeIndex);
		}
	}, [opened, swiper, activeIndex]);

	const chat = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === NaN || chat_id === "null") return {};
			return await db.chats.get(chat_id);
		},
		[chat_id],
		{}
	);

	useEffect(() => {
		if (media.length > 0 && !activeDocument?.uri) {
			const firstMedia = media[0];
			if (firstMedia.type === "mms") {
				setActiveDocument({
					uri: firstMedia.mmsFile.url,
					fileName: firstMedia.mmsFile.url.split("/").pop(),
				});
			} else {
				setActiveDocument({
					uri: firstMedia.audio_url,
					fileName: firstMedia.audio_url.split("/").pop(),
				});
			}
		}

		if (selectedMesageId && media.length > 0) {
			const index = media.findIndex((item) => item.id === selectedMesageId);
			if (media[index]?.type === "mms") {
				setActiveDocument({
					uri: media[index].mmsFile.url,
					fileName: media[index].mmsFile.url.split("/").pop(),
				});
			} else {
				setActiveDocument({
					uri: media[index]?.audio_url,
					fileName: media[index]?.audio_url.split("/").pop(),
				});
			}
			setActiveIndex(index);
			f7.store.dispatch("setSelectedMessageId", null);
		}
	}, [media, selectedMesageId]);

	const navigate = useCallback(
		(direction) => {
			if (media.length === 0) return;

			setActiveIndex((prev) => {
				let newIndex = prev + direction;
				if (newIndex >= media.length) newIndex = 0;
				if (newIndex < 0) newIndex = media.length - 1;

				if (media[newIndex].type === "mms") {
					setActiveDocument({
						uri: media[newIndex].mmsFile.url,
						fileName: media[newIndex].mmsFile.url.split("/").pop(),
					});
				} else {
					setActiveDocument({
						uri: media[newIndex]?.audio_url,
						fileName: media[newIndex]?.audio_url.split("/").pop(),
					});
				}
				return newIndex;
			});
		},
		[media]
	);
	const startChat = useCallback(async () => {
		if (!media[activeIndex].sender_id) return;

		const contact = await db.users
			.where({ id: media[activeIndex].sender_id })
			.first();

		if (contact.chat_id) {
			if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
				f7.views.main.router.navigate(`/chats/null/single`);
			f7.store.dispatch("setActiveConversation", parseInt(contact.chat_id));
			f7.store.dispatch("setActiveConversationType", "single");
		} else {
			const identifier = uuidv4();
			socket.emit(
				ENDPOINTS.START_CHAT,
				JSON.stringify({
					chat_id: 0,
					receiver_id: contact?.id,
					identifier: identifier,
					group_id: identifier,
					user_id: contact?.id,
				}),
				(response) => {
					const addChat = async () => {
						await db.chats.add({
							chat_id: response,
							can_send: true,
							disappearing_duration: null,
							accepted_by: null,
							durition: 0,
							firstname:
								contact?.firstname || contact?.name || contact?.kalam_name,
							hide_profile_picture: contact?.hide_profile_picture || false,
							hide_screenshot: null,
							image: null,
							is_muted: 0,
							is_online: 0,
							is_private_chat: 1,
							is_read: 0,
							last_message_id: null,
							lastname: contact?.lastname || null,
							message: null,
							message_sender_id: null,
							mobile: contact?.mobile || contact?.kalam_number,
							msg_type: null,
							nickname:
								contact?.nickname || contact?.name || contact?.kalam_name,
							owner_id: loginResponse?.data?.user_id,
							profile_image: contact?.profile_image || null,
							reaction: null,
							sender_id: null,
							type: "single",
							un_read_count: 0,
							unix_time: Date.now() / 1000,
							user_id: contact?.id,
						});
					};
					db.chats
						.where({ chat_id: response })
						.first()
						.then(async (chat) => {
							if (!chat) await addChat();
							if (
								isMobile &&
								f7.router.currentRoute.path !== "/chats/null/single"
							)
								f7.views.main.router.navigate(`/chats/null/single`);
							f7.store.dispatch("setActiveConversation", parseInt(response));
							f7.store.dispatch("setActiveConversationType", "single");
						})
						.catch(async (error) => {
							await addChat();
							if (
								isMobile &&
								f7.router.currentRoute.path !== "/chats/null/single"
							)
								f7.views.main.router.navigate(`/chats/null/single`);
							f7.store.dispatch("setActiveConversation", parseInt(response));
							f7.store.dispatch("setActiveConversationType", "single");
						});
				}
			);
		}
	}, [media, activeIndex]);

	const BackButton = () => (
		<div className="h-full p-0 md:p-5 cursor-pointer flex flex-col align-center justify-center">
			<Button onClick={() => navigate(-1)} className="p-0 h-auto">
				<MaterialIcon
					icon="chevron_left"
					size={24}
					className="text-[30px]"
					color="#A6A9AF"
				/>
			</Button>
		</div>
	);

	const ForwardButton = () => (
		<div className="h-full p-0 md:p-5 cursor-pointer flex flex-col align-center justify-center">
			<Button onClick={() => navigate(1)} className="p-0 h-auto">
				<MaterialIcon
					icon="chevron_right"
					size={24}
					className="text-[30px]"
					color="#A6A9AF"
				/>
			</Button>
		</div>
	);

	useKey("ArrowLeft", () => navigate(-1), [navigate], [media]);
	useKey("ArrowRight", () => navigate(1), [navigate], [media]);

	useUpdateEffect(() => {
		if (media.length > 0 && media[activeIndex]?.type === "video" && opened) {
			const video = document.querySelector(
				`video[id="${media[activeIndex]?.id}"]`
			);
			video && video.play();
		}
	}, [media, activeIndex, opened]);

	return (
		<Page className="bg-black/80 backdrop-blur md:backdrop-blur-none">
			<Navbar transparent className="mt-[10px] md:mt-[30px]">
				<NavLeft transparent className="ms-[10px] md:ms-[30px]">
					<LazyLoaderWraper
						src={
							chat && !chat?.hide_profile_picture && chat?.profile_image?.length
								? chat.profile_image
								: chatType === "single"
								? userimage
								: groupimage
						}
						placeholder={userimage}
						height={45}
						width={45}
						alt=""
						className="rounded-full align-bottom"
						wrapperclassname="rounded-full align-bottom"
					/>
					<div className="flex flex-col justify-center items-start ml-[10px]">
						<span className="dark-theme text-body text-2xl font-medium text-ellipsis max-w-[335px]">
							{chat && chat?.nickname ? chat.nickname : `${chat?.firstname}`}
						</span>
					</div>
				</NavLeft>
				<NavRight className="me-[10px] md:me-[30px]">
					{chat?.type === "group" && (
						<Button onClick={startChat}>
							<img
								src={message_icon}
								className="m-[10px] text-white text-2xl cursor-pointer"
								size={24}
								weight={70}
								height={70}
								color="#A6A9AF"
							/>
						</Button>
					)}
					<Button
						onClick={() => {
							setFileToDownload(media[activeIndex]);
							setShowDownloder(true);
						}}>
						<MaterialIcon
							icon="file_download"
							className="m-[10px]  text-2xl cursor-pointer"
							size={24}
							weight={70}
							height={70}
							color="#A6A9AF"
						/>
					</Button>
					<Button
						onClick={() => {
							setMessageContext(media[activeIndex]);
							onForwardPanel();
						}}>
						<MaterialIcon
							icon="shortcut"
							className="m-[10px] text-2xl cursor-pointer"
							size={24}
							weight={70}
							height={70}
							color="#A6A9AF"
						/>
					</Button>
					<Button
						onClick={() => {
							closePopup();
							setActiveDocument(null);
							setActiveIndex(0);
							setLightboxOpened(false);
							onDeleteMessage(media[activeIndex]);
						}}>
						<MaterialIcon
							icon="delete"
							className="m-[10px]  text-2xl cursor-pointer"
							size={24}
							weight={70}
							height={70}
							color="#A6A9AF"
						/>
					</Button>
					<Button
						onClick={() => {
							closePopup();
							setActiveDocument(null);
							setActiveIndex(0);
							setLightboxOpened(false);
						}}>
						<MaterialIcon
							icon="close"
							className="m-[10px] text-2xl cursor-pointer"
							size={24}
							weight={70}
							height={70}
							color="#A6A9AF"
						/>
					</Button>
				</NavRight>
			</Navbar>
			<div className="flex flex-col items-center justify-between self-stretch h-full">
				<div
					className={`file-container max-h-[80%] h-full ${
						getFileTypeByName(activeDocument?.fileName) !== "pdf"
							? "self-stretch"
							: "overflow-hidden"
					} flex items-center justify-between`}>
					<BackButton />
					{getFileTypeByName(activeDocument?.fileName) === "image" ? (
						<img
							key={activeDocument?.fileName}
							src={activeDocument?.uri}
							className="max-h-full max-w-[80%]"
						/>
					) : getFileTypeByName(activeDocument?.fileName) === "video" ? (
						<video
							id={media[activeIndex]?.id}
							key={activeDocument?.fileName}
							controls
							autoPlay={opened}
							playsInline
							className="max-h-full max-w-[80%]">
							<source src={activeDocument?.uri} />
						</video>
					) : null}
					<ForwardButton />
				</div>
				<div className="caption-container w-[85%] text-center dark-theme text-body p-5 break-words">
					{media.length > 0 && (
						<MarkdownPreview
							source={removeMD(
								media[activeIndex]?.caption?.replace(
									/@\[([^\]]+)\]\((\d+)\)/g,
									"@$1"
								)
							)}
							rehypePlugins={[rehypeSanitize]}
						/>
					)}
				</div>
				{/* <div className="swiper-container w-full flex items-center justify-center"> */}
				{/* <swiper-container space-between="5" slides-per-view="auto"> */}
				<Swiper
					className="w-full"
					spaceBetween={5}
					slidesPerView={window.innerWidth / 67}
					centeredSlides={true}
					freeMode={true}
					grabCursor={true}
					onSwiper={setSwiper}>
					{media.map((item, index) => (
						<SwiperSlide key={index}>
							<Button
								onClick={() => {
									if (item?.type === "mms") {
										setActiveDocument({
											uri: item.mmsFile.url,
											fileName: item.mmsFile.url.split("/").pop(),
										});
									} else {
										setActiveDocument({
											uri: item?.audio_url,
											fileName: item?.audio_url.split("/").pop(),
										});
									}
									setActiveIndex(index);
								}}
								outline
								className={`rounded-[5px] lightbox-thumbnail-button p-0 ${
									activeIndex === index && "active"
								}`}>
								<LightboxThumbnail
									item={item}
									mms={item.type === "mms" ? item.mmsFile.url : null}
								/>
								{activeIndex === index && <span className="overlay"></span>}
							</Button>
						</SwiperSlide>
					))}
				</Swiper>
				{/* </swiper-container> */}
				{/* </div> */}
			</div>
			{fileToDownload && (
				<DownloaderDialog
					open={showDownloader}
					url={fileToDownload.audio_url}
					filename={
						fileToDownload.message || fileToDownload.audio_url.split("/").pop()
					}
					onClose={() => {
						setShowDownloder(false);
						setFileToDownload(null);
					}}
				/>
			)}
		</Page>
	);
};

export default Lightbox;
