import React, { useContext, useEffect, useState, useRef } from "react";
import {
	Block,
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	Preloader,
	f7,
	List,
	ListItem,
	Button,
	Popup,
	Subnavbar,
	Searchbar,
	useStore,
} from "framework7-react";
import { Message, Profile, ProfileAdd } from "iconsax-react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import { useDispatch, useSelector } from "react-redux";
import user from "../../../../assets/images/placeholders/user.png";
import admin from "../../../../assets/images/icons/admin-user-blue.svg";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";
import { useUpdateEffect } from "react-use";
import { getGroupDetailService } from "../../../../redux/features/chatSlice/groupDetail";
import { db } from "../../../../js/db";
import { useLiveQuery } from "dexie-react-hooks";
import "./style.css";
import { v4 as uuidv4 } from "uuid";
import { editGroupService } from "../../../../redux/features/chatSlice/editGroup";
import { useTranslation } from "react-i18next";
import { userProfileService } from "../../../../redux/features/userSlice/profile";

const GroupMembers = ({ chat_id, switchMode, openAddMembers }) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	const { t, i18n } = useTranslation();
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const dispatch = useDispatch();
	const { isLoading } = useSelector((state) => state.groupDetailSlice);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const socket = useContext(SocketContext);
	const [groupData, setGroupData] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const searchbar = useRef(null);
	const SearchList = useRef(null);
	const [popupData, setPopupData] = useState(null);
	const [isadmin, setIsAdmin] = useState(false);
	const [selectedUser, setSelectedUser] = useState(0);

	useUpdateEffect(() => {
		setGroupData(false);
		setIsPopupOpen(false);
		setPopupData(null);
		setIsAdmin(false);
		setSelectedUser(0);
		searchbar.current && f7.searchbar.destroy(searchbar.current.el);
	}, [chat_id]);
	const members = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || chat_id === NaN) return [];
			return await db.members.where({ chat_id: chat_id }).toArray();
		},
		[chat_id],
		[]
	);

	useEffect(() => {
		if (!members || members.length === 0) return;

		members.map((item) => {
			if (loginResponse.data.id == item.id && item.is_admin) {
				setIsAdmin(true);
			}
		});
	}, [members]);

	useEffect(() => {
		dispatch(getGroupDetailService({ chat_id }));
	}, [chat_id]);

	// useUpdateEffect(() => {
	// 	setGroupData(getGroupDetailResponse?.data?.[0]);
	// 	setVlData({ items: getGroupDetailResponse?.data?.[0]?.users_results });
	// 	// setMuteChecked(userProfileResponse?.data?.[0].is_mute);
	// 	// setForwardChecked(userProfileResponse?.data?.[0].allow_forwarding);
	// }, [getGroupDetailResponse]);

	const dateString = groupData?.created_at;
	const dateObject = new Date(dateString);
	const options = { year: "numeric", month: "long", day: "numeric" };
	const createdAt = dateObject.toLocaleDateString("en-US", options);

	// const renderExternal = (vl, newData) => {
	// 	setVlData({ ...newData });
	// };

	// const searchAll = (query, searchItems) => {
	// 	const found = [];
	// 	for (let i = 0; i < searchItems.length; i += 1) {
	// 		if (
	// 			searchItems[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
	// 			query.trim() === ""
	// 		)
	// 			found.push(i);
	// 	}
	// 	return found;
	// };

	useEffect(() => {
		if (members?.length > 0) {
			// if (vlData.current) {
			// 	f7.virtualList.create({
			// 		el: Data.current.el,
			// 		items: vlData,
			// 		createUl: false,
			// 		renderExternal,
			// 		searchAll,
			// 		height: "auto",
			// 	});
			// }
			if (searchbar.current) {
				f7.searchbar.create({
					el: searchbar.current.el,
					backdrop: false,
					inner: false,
					disableButton: false,
					customSearch: false,
				});
			}
		}
	}, [members, searchbar]);

	const userProfile = useLiveQuery(
		() => db.users.where({ id: parseInt(selectedUser) }).first(),
		[selectedUser],
		null
	);

	const openPopup = (userData) => {
		setPopupData(userData);
		setIsPopupOpen(true);
	};

	const deleteGroupAdmin = () => {
		// Assuming popupData contains the user's information, including user_id
		const userId = popupData?.user_id;
		const chatsid = popupData?.chats_id;
		// Emit a socket event to the server to make the user a group admin
		socket.emit(
			ENDPOINTS.DELETE_GROUP_ADMINS,
			JSON.stringify({
				chat_id: chatsid,
				user_id: userId,
			}),
			(response) => {
				db.members
					.where({ chat_id: parseInt(chatsid), id: parseInt(userId) })
					.modify({
						is_admin: 0,
					});
			}
		);
		f7.popup.close();
	};
	const makeGroupAdmin = () => {
		// Assuming popupData contains the user's information, including user_id
		const userId = popupData?.user_id;

		const chatsid = popupData?.chats_id;

		// Emit a socket event to the server to make the user a group admin
		socket.emit(
			ENDPOINTS.ADD_GROUP_ADMIN,
			JSON.stringify({
				chat_id: chatsid,
				user_id: userId,
			}),
			(response) => {
				db.members
					.where({ chat_id: parseInt(chatsid), id: parseInt(userId) })
					.modify({
						is_admin: 1,
					});
			}
		);
		f7.popup.close();
	};
	const removedFromGroup = () => {
		dispatch(
			editGroupService({
				chat_id: popupData?.chats_id,
				remove_group_members: [popupData?.user_id],
			})
		);
		db.members
			.where({
				chat_id: parseInt(popupData?.chats_id),
				id: parseInt(popupData?.user_id),
			})
			.delete();
		f7.popup.close();
	};
	const viewPorfile = () => {
		f7.popup.close();
		f7.panel.close();
		f7.store.dispatch("setSelectedUserId", popupData?.user_id);
	};

	const openMemberPopup = (contact) => {
		dispatch(
			userProfileService({
				user_id: contact?.id,
			})
		);
		setSelectedUser(contact?.id);
		openPopup({
			image: contact?.profile_image || user,
			name: contact.firstname ? contact.firstname : contact.username,
			user_id: contact?.id,
			chats_id: chat_id,
			is_admin: contact?.is_admin,
			contact: contact,
		});
	};

	return (
		<Page>
			<Navbar>
				<NavLeft>
					<Button onClick={switchMode}>
						<MaterialIcon size={48} icon="close" color="black" />
					</Button>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("GROUP MEMBERS")}
				</NavTitle>
			</Navbar>
			<Subnavbar>
				{members?.length > 0 && (
					<Searchbar
						ref={searchbar}
						inline
						backdrop={false}
						disableButton={false}
						inner={false}
						placeholder={t("search")}
						className="w-full"
						searchContainer=".group-contact-list"
						searchIn=".item-title"
					/>
				)}
			</Subnavbar>
			<Block>
				{isadmin && (
					<div className="flex items-end justify-between">
						<List className="mb-[0px]">
							<ListItem
								title={t("Add Members")}
								className="cursor-pointer"
								onClick={openAddMembers}>
								<div
									slot="media"
									className="flex justify-center items-center w-[55px] h-[55px] rounded-full bg-Light-theme">
									<ProfileAdd
										size={"25"}
										color={"#0183e2"}
										variant="Bold"
										icon="ProfileAdd"
									/>
								</div>
							</ListItem>
						</List>
					</div>
				)}
				<div>
					{members?.length === 0 && isLoading ? (
						<div className="flex flex-col justify-center items-center h-100 w-100">
							<Preloader />
						</div>
					) : (
						<>
							{members?.length > 0 ? (
								<List
									ref={SearchList}
									strongIos
									className="search-list group-contact-list mt-0 searchbar-found">
									<ul className="h-full">
										{members?.map((contact) => (
											<ListItem
												onClick={() => openMemberPopup(contact)}
												key={contact?.id}
												className="cursor-pointer">
												<LazyLoaderWraper
													slot="media"
													src={contact?.profile_image || user}
													placeholder={user}
													height={55}
													width={55}
													alt=""
													className="rounded-full align-bottom"
													wrapperclassname="rounded-full align-bottom"
												/>
												<p
													slot="title"
													className="text-[16px] font-[700] text-[--f7-heading-color] overflow-hidden text-ellipsis whitespace-nowrap">
													{contact.firstname
														? contact.firstname
														: contact.username}
												</p>
												{contact?.is_admin === 1 && (
													<div
														slot="after"
														className="text-[12px] font-[600]
																px-2 rounded-[5px] bg-[--f7-md-primary-light] flex items-center
																text-[--f7-md-primary] whitespace-nowrap">
														<p>{t("Group Admin")}</p>
													</div>
												)}
											</ListItem>
										))}
									</ul>
								</List>
							) : (
								<List
									strongIos
									outlineIos
									dividersIos
									className="searchbar-not-found">
									<ListItem title={t("Nothing found")} />
								</List>
							)}
						</>
					)}
					<Popup
						opened={isPopupOpen}
						animate
						className={`group-edit-popup${
							popupData?.is_admin === 1 ? " admin-popup" : ""
						}`}
						onPopupClosed={() => setIsPopupOpen(false)}>
						<div className="popup-content p-[20px] gap-[20px]">
							<Button
								className="absolute right-0 top-0 cursor-pointer"
								popupClose>
								<MaterialIcon size={13} icon="close" color="#E3E3E3" />
							</Button>
							<Block className="flex pb-[25px] my-0 pt-2 flex-col w-full items-center">
								<LazyLoaderWraper
									src={popupData?.image || user}
									placeholder={user}
									height={160}
									width={160}
									alt=""
									className="rounded-full align-bottom"
									wrapperclassname="rounded-full align-bottom"
								/>
								<p className="text-[34px] text-[--f7-heading-color] font-[700] pt-[20px]  overflow-hidden text-ellipsis whitespace-nowrap max-w-full max-h-[85px]">
									{popupData?.name}
								</p>
								{/* <p className="text-[16px] text-[black] font-[500]">firelordsynder</p> */}
							</Block>
							<Block>
								<>
									{userProfile?.blocked !== 1 ? (
										userProfile?.chat_id ? (
											<Link
												popupClose
												className="flex gap-[10px] items-center justify-start mt-6 ms-[25px] cursor-pointer"
												href={`/chats/${userProfile?.chat_id}/single`}>
												<Message
													size={"25"}
													color={"#0e99fe"}
													variant="Bold"
													icon="Message"
												/>
												<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
													{t("Message")}
												</p>
											</Link>
										) : (
											<Button
												popupClose
												className="flex h-[25px] p-0 gap-[10px] items-center justify-start mt-6 ms-[25px]"
												onClick={() => {
													const identifier = uuidv4();
													socket.emit(
														ENDPOINTS.START_CHAT,
														JSON.stringify({
															chat_id: 0,
															receiver_id: userProfile?.id,
															identifier: identifier,
															group_id: identifier,
															user_id: loginResponse?.data?.user_id,
														}),
														(response) => {
															db.users.update(userProfile?.id, {
																chat_id: response,
															});
															dispatch(conversationService({}));
															f7.views.main.router.navigate(
																`/chats/${response}/single`
															);
														}
													);
												}}>
												<Message
													size={"25"}
													color={"#0e99fe"}
													variant="Bold"
													icon="Message"
												/>
												<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
													{t("Message")}
												</p>
											</Button>
										)
									) : null}

									<Link
										popupClose
										panelClose
										className="flex gap-[10px] items-center justify-start mt-6 ms-[25px] cursor-pointer"
										onClick={viewPorfile}>
										<Profile
											size={"25"}
											color={"#0e99fe"}
											icon="Profile"
											variant="Bold"
										/>

										<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
											{t("View profile")}
										</p>
									</Link>
								</>
								{popupData?.is_admin === 0 &&
									loginResponse?.data.id !== popupData?.user_id &&
									isadmin && (
										<>
											<div
												className="flex gap-[10px] items-center mt-6 ms-[25px] cursor-pointer"
												onClick={makeGroupAdmin}>
												<img
													src={admin}
													size={"25"}
													color={"#0e99fe"}
													variant="Bold"
													icon="admin"
												/>

												<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
													{t("Make group admin")}
												</p>
											</div>
											<div
												className="flex gap-[10px] items-center mt-6 ms-[25px] cursor-pointer"
												onClick={removedFromGroup}>
												<ProfileAdd
													size={"25"}
													color={"#E05047"}
													variant="Bold"
													icon="ProfileAdd"
												/>

												<p className="text-[16px] text-[#E05047] font-[600] mb-[0]">
													{t("Remove from group")}
												</p>
											</div>
										</>
									)}
								{popupData?.is_admin === 1 &&
									loginResponse?.data.id !== popupData?.user_id && (
										<>
											<div
												className="flex gap-[10px] items-center mt-6 ms-[25px] cursor-pointer"
												onClick={deleteGroupAdmin}>
												<ProfileAdd
													size={"25"}
													color={"#E05047"}
													variant="Bold"
													icon="ProfileAdd"
												/>

												<p className="text-[16px] text-[#E05047] font-[600] mb-[0]">
													{t("Admin remove")}
												</p>
											</div>
										</>
									)}
							</Block>
						</div>
					</Popup>
				</div>
			</Block>
		</Page>
	);
};

export default GroupMembers;
