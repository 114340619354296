import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";
import { db } from "../../../js/db";
import Dexie from "dexie";

const initialState = {
	getGroupDetailResponse: {},
	isLoading: false,
	error: false,
};

export const getGroupDetailSlice = createSlice({
	name: "getGroupDetail",
	initialState,
	reducers: {
		saveGetGroupDetailResponse: (state, action) => {
			state.getGroupDetailResponse = action.payload;
		},
		resetGetGroupDetailResponse: (state) => {
			state.getGroupDetailResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGroupDetailService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getGroupDetailService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.getGroupDetailResponse = action.payload;
				const { users_results, ...details } = action.payload?.data[0];
				delete Object.assign(details, { ["chat_id"]: details["id"] })["id"];
				console.log("group detail response", users_results, details);
				db.members
					.bulkPut(users_results)
					.catch(Dexie.BulkError, function (err) {});
				db.chats.where({ chat_id: details?.chat_id }).modify((value, ref) => {
					ref.value = { ...value, ...details };
				});
			})
			.addCase(getGroupDetailService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const getGroupDetailService = createAsyncThunk(
	"chat/getGroupDetail",
	async (values) => {
		if (!values) return;

		return post("/chat/get_group_detail", {
			type: "application/json",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveGetGroupDetailResponse, resetGetGroupDetailResponse } = getGroupDetailSlice.actions;
export { getGroupDetailService };

export default getGroupDetailSlice.reducer;
