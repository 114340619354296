import { f7, useStore } from "framework7-react";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  VideoToFrames,
  VideoToFramesMethod,
} from "../components/StoriesPage/GetThumbnail";
import {
  postStory,
  updateIspostingStatus,
} from "../redux/features/storiesSlice";
import {
  updateStoryText,
  updateStoryType,
} from "../redux/features/storiesSlice/userStory";
import { dataURItoBlob } from "../utils/functions";

function useStory() {
  const { t } = useTranslation();
  const inputRef = useRef();
  const canvas = useStore("canvas");
  const profile = useSelector(
    (store) => store?.loginSlice?.loginResponse?.data
  );
  const dispatch = useDispatch();
  const stories = useSelector((store) => store?.stories?.stories);
  const [userRecentStories, setUserRecentStories] = useState([]);
  const [userViewedStories, setUserViewedStories] = useState([]);
  useMemo(() => {
    let uniqueUserIds = new Set();
    let filteredMessages = stories?.filter((message) => {
      if (
        message.is_read === 0 &&
        !uniqueUserIds.has(message.user_id) &&
        message?.user_id !== profile?.user_id
      ) {
        uniqueUserIds.add(message.user_id);
        return true;
      }
      return false;
    });

    let unreadMessagesNotInFiltered = stories?.filter((message) => {
      return (
        message.is_read === 1 &&
        !filteredMessages.some(
          (filteredMsg) =>
            filteredMsg.user_id === message.user_id &&
            filteredMsg?.user_id !== profile?.user_id
        )
      );
    });

    let uniqueUserIdsUnread = new Set();

    let uniqueUnreadMessagesNotInFiltered = unreadMessagesNotInFiltered?.filter(
      (message) => {
        if (
          !uniqueUserIdsUnread.has(message.user_id) &&
          message?.user_id !== profile?.user_id
        ) {
          uniqueUserIdsUnread.add(message.user_id);
          return true;
        }
        return false;
      }
    );
    setUserRecentStories(filteredMessages);
    setUserViewedStories(uniqueUnreadMessagesNotInFiltered);
  }, [stories]);

  const postStoryHandler = (story) => {
    const type = story?.type?.slice(0, 5);
    dispatch(updateIspostingStatus(true));
    if (story && (type === "image" || type === "video")) {
      f7.toast
        .create({
          text: "Sending...",
          position: "top",
          horizontalPosition: "right",
          closeTimeout: "18000000000",
        })
        .open();
      if (type !== "image") {
        const fileUrl = URL.createObjectURL(story);
        VideoToFrames.getFrames(
          fileUrl,
          1,
          VideoToFramesMethod.totalFrames
        ).then((res) => {
          dispatch(
            postStory({
              type,
              file: story,
              identifier: uuidv4(),
              thumbnail: res?.[0],
            })
          );
        });
      } else {
        try {
          const DATA_FILE = canvas.toDataURL({
            format: "jpeg",
            quality: 1,
          });
          const file = dataURItoBlob(DATA_FILE);
          dispatch(
            postStory({
              type,
              file,
              identifier: uuidv4(),
              thumbnail: file,
            })
          );
        } catch (error) {
          f7.toast.close();
          dispatch(updateIspostingStatus(false))
          f7.toast.create({
            text: error,
            position: "top",
            horizontalPosition: "right",
            closeTimeout: "2000",
          }).open();
        }
      }
    }
  };

  const defaultStorySetting = () => {
    f7.store.dispatch("setStoryFile", null);
    dispatch(updateStoryText(""));
    dispatch(updateStoryType(null));
  };

  return {
    postStoryHandler,
    inputRef,
    t,
    defaultStorySetting,
    userRecentStories,
    userViewedStories,
  };
}

export default useStory;
