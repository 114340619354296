import {
	Button,
	Link,
	List,
	ListInput,
	ListItem,
	Page,
	PageContent,
	Preloader,
	f7,
	Popup,
} from "framework7-react";
import { Eye, EyeSlash, Lock, Profile } from "iconsax-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { APPLE_SDK, AUTHTOKEN_COOKIE, STATUS_CODE } from "../../constants";
import {
	loginService,
	socialLoginService,
	loginwithPoshService,
	resetLoginResponse,
} from "../../redux/features/authSlice/login";
import { SocketContext } from "../../socket";
import { setCookie } from "../../utils/functions";
import Posh from "../../assets/images/icons/Posh.svg";
import Frame from "../../assets/images/icons/Frame.svg";
import AuthNabvar from "../../components/navs/authNavbar";
import PhoneInput from "../../components/inputs/phone-input";
import { useTranslation } from "react-i18next";
import Googlelogin from "../../components/AuthButtons/googleLogIn";
import AppleLoginButton from "../../components/AuthButtons/AppleLoginButton";
import "./style.css";
import { handshake } from "../../utils/handShake";
import { db } from "../../js/db";

const LoginPage = ({ f7router }) => {
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const focusTimeout = useRef(null);

	const { loginResponse, isLoading } = useSelector((state) => state.loginSlice);
	const [username, setUsername] = useState("");
	const [usernameworldnoor, setUsernameworldnoor] = useState("");
	const [passwordworldnoor, setPasswordworldnoor] = useState("");
	const [isValidEmailaddress, setIsValidEmailaddress] = useState("");
	const [isValidPasswordworldnoor, setIsValidPasswordnoor] = useState(true);
	const [passwordvalue, setPasswordValue] = useState("password");
	const [phoneNumber, setPhoneNumber] = useState({
		phone: "",
		country: "",
		countryCode: "",
		validationError: false,
	});
	const [isPhone, setIsPhone] = useState(false);
	const [password, setPassword] = useState("");
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [isValidEmail, setIsValidEmail] = useState("");
	const [isValidPassword, setIsValidPassword] = useState(true);
	const [isError, setIsError] = useState(false);
	const { t, i18n } = useTranslation();
	const [update, forceUpdate] = useState(0);

	useEffect(() => {
		dispatch(resetLoginResponse());
		focusTimeout.current = setTimeout(() => {
			const input = document.querySelector("input[type='email']");
			input.focus();
		}, 1000);

		return () => {
			clearTimeout(focusTimeout.current);
		};
	}, []);

	useEffect(() => {
		document.addEventListener("onautocomplete", (e) => {
			if (e.target.hasAttribute("autocompleted")) {
				forceUpdate((n) => n + 1);
			}
		});

		return () => {
			document.removeEventListener("onautocomplete", (e) => {
				if (e.target.hasAttribute("autocompleted")) {
					forceUpdate((n) => n + 1);
				}
			});
		};
	}, []);

	const signIn = (e) => {
		e.preventDefault();
		e.stopPropagation();
		//validate here
		if ((isPhone && !phoneNumber) || (!isPhone && (!username || !password))) {
			setIsError(true); // Set isError to true when there's an error
			f7.toast.create({ text: "Please fill in all fields" }).open();
			return;
		}

		setIsError(false); // Reset isError when there's no error
		dispatch(
			loginService({
				login: isPhone
					? `${phoneNumber.countryCode}${phoneNumber.phone.replace(/^0+/, "")}`
					: username,
				password: password,
			})
		);
	};

	const LoginWithPosh = (e) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(
			loginwithPoshService({
				login: usernameworldnoor,
				password: passwordworldnoor,
			})
		);
	};

	const handleEmailChange = (e) => {
		const enteredEmail = e.target.value;
		setUsername(enteredEmail);
		setIsError(false);
	};

	const handlePasswordChange = (e) => {
		const enteredpassword = e.target.value;
		setPassword(enteredpassword);
		setIsError(false);
	};

	useUpdateEffect(() => {
		if (loginResponse.code === STATUS_CODE.SUCCESS) {
			setCookie(AUTHTOKEN_COOKIE, loginResponse.data.token);
			f7.emit("loginSuccess", loginResponse.data.id); //app's internal event for re-initializing socket with authtoken
			f7router.navigate(
				`${window.innerWidth < 775 ? "/chats/" : "/chats/null/single"}`
			);
		} else {
			//show error
			if (loginResponse.message) {
				f7.toast.create({ text: t(loginResponse.message) }).open();
				setIsError(true);
			}
		}
	}, [loginResponse]);

	const handlePoshImageClick = () => {
		setIsPopupOpen(true);
	};

	const EmailChange = (e) => {
		const enteredEmail = e.target.value;
		setUsernameworldnoor(enteredEmail);
	};

	const PasswordChange = (e) => {
		const enteredpassword = e.target.value;
		setPasswordworldnoor(enteredpassword);
	};

	const handleLogin = (logindata) => {
		dispatch(
			socialLoginService({
				email: logindata.email,
				token: logindata.token,
				type: logindata.type,
				device_type: logindata.device_type,
				firstname: logindata.fristname,
				profile_picture: logindata.profile_picture,
			})
		);
	};

	return (
		<Page
			name="login"
			loginScreen
			pageContent={false}
			className="main-bg-bubble"
			onPageInit={async () => {
				window.AppleID.auth.init(APPLE_SDK);
				if (!db.isOpen()) {
					db.open().catch(function (err) {
						console.error("Failed to open db: " + (err.stack || err));
					});
				}
				!localStorage.getItem("keys") && (await handshake());
			}}>
			<AuthNabvar />
			<PageContent className="overflow-hidden flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px]">
				<div className="w-full md:w-[400px] h-[-webkit-fill-available]">
					<div className="pl-[30px]">
						<p className="text-[26px] font-boldtext-[--f7-heading-color-color]">
							{t("Welcome back!")}
						</p>
						<p className="text-[18px]text-[--f7-heading-color-color]">
							{t("We are so excited to see you again!")}
						</p>
					</div>
					<List className="list-none">
						{isPhone ? (
							<PhoneInput onChange={setPhoneNumber} />
						) : (
							<ListInput
								type="email"
								placeholder={t("Enter email address")}
								className={`input-field-normal w-full`}
								value={username}
								required
								onChange={handleEmailChange}
								autofocus={true}>
								<div slot="media">
									<Profile color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
						)}
					</List>
					<List>
						<ListInput
							type={passwordType}
							placeholder={t("Enter password")}
							className={`input-field-normal mt-5`}
							value={password}
							required
							onChange={handlePasswordChange}>
							<div slot="content-end" className=" cursor-pointer">
								{passwordType === "password" ? (
									<Eye
										onClick={() => setPasswordType("text")}
										color="#5d6980"
										variant="Bold"
										size={24}
									/>
								) : (
									<EyeSlash
										onClick={() => setPasswordType("password")}
										color="#5d6980"
										variant="Bold"
										size={24}
									/>
								)}
							</div>
							<div slot="media">
								<Lock color="#5d6980" variant="Bold" size={22} />
							</div>
						</ListInput>
						<ListItem>
							<div className="flex justify-between flex-wrap gap-4 w-full text-[16px] text-[--f7-md-primary] pl-2">
								<div
									onClick={() => setIsPhone(!isPhone)}
									className="cursor-pointer">
									{t("Sign in with")} {isPhone ? t("Email") : t("Phone number")}
								</div>
								<Link href="/ForgetPasswordPage" className="mr-[-20px]">
									{" "}
									{t("Forgot Password ?")}
								</Link>
							</div>
						</ListItem>
						<ListItem className="mt-5 button-container">
							<Button
								type="submit"
								fill
								id="login-button"
								className="w-full"
								onClick={signIn}
								disabled={
									(isPhone &&
										(!phoneNumber.phone ||
											phoneNumber.validationError ||
											!password)) ||
									(!isPhone && (!username || !password)) ||
									isLoading ||
									isError
								}>
								{isLoading ? <Preloader color="white" /> : t("Sign in")}
							</Button>
						</ListItem>
						<ListItem className="mt-4 pl-1">
							<div className="w-full flex mr-[-15px] items-center gap-2">
								<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-40" />
								<p className="text-[14px] text-[--f7-chat-heading-color]">
									{" "}
									{t("OR SIGN IN WITH")}
								</p>
								<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-40" />
							</div>
						</ListItem>

						<ListItem className="pl-1">
							<div className=" w-full flex justify-between">
								<span className="cursor-pointer pt-[10px]">
									<Googlelogin onSignIn={handleLogin} />
								</span>
								<span className="cursor-pointer">
									<AppleLoginButton />
								</span>
								{/* <span>
									<img  src={Facebook} alt="Icon" />
								</span> */}
								<span className="cursor-pointer" onClick={handlePoshImageClick}>
									<img src={Posh} alt="Icon" />
								</span>
							</div>
						</ListItem>
						<ListItem className="mt-4 pl-1">
							<div className=" w-full flex justify-center">
								<p className="text-[16px]  text-[--f7-body-text]">
									<span className="opacity-70">
										{" "}
										{t("Don't have an account?")}{" "}
									</span>
									<Link className="text-[--f7-md-primary]" href="/signup">
										{" "}
										{t("Sign up")}
									</Link>
								</p>
							</div>
						</ListItem>
					</List>
				</div>
				<div className="w-full md:w-[400px] h-[-webkit-fill-available] flex flex-col items-center justify-center">
					<img src={Frame} alt="Icon" />
				</div>
			</PageContent>
			<Popup
				className="w-[501px] h-[547px]"
				opened={isPopupOpen}
				onPopupClosed={() => setIsPopupOpen(false)}>
				<PageContent className="flex mt-[10px] px-[20px] md:px-0 justify-center flex-wrap gap-[100px] ">
					<div className="w-full md:w-[400px]">
						<div className="grid justify-evenly">
							<span className="cursor-pointer flex justify-center items-center">
								<img src={Posh} alt="Icon" />
								<p className="text-[26px] font-boldtext-[--f7-heading-color-color]">
									{t("WN Social")}
								</p>
							</span>
							<p className="w-[373px] h-[69px] mb-[10px] font-normal text-[15px] leading-[22.50px] tracking-[0.35px] font-[Poppins]">
								{t(
									"WN Social is your gateway to enter all apps developed by Posh. Create your WN Social ID or login if you already have one."
								)}
							</p>
						</div>
						<List className="list-none">
							<ListInput
								type="text"
								placeholder={t("Enter email address")}
								className="input-field-normal w-full "
								value={usernameworldnoor}
								onInput={(e) => {
									setUsernameworldnoor(e.target.value);
								}}
								required
								onChange={EmailChange}>
								<div slot="media">
									<Profile color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
						</List>
						<List>
							<ListInput
								type={passwordvalue}
								placeholder={t("Enter password")}
								className="input-field-normal "
								value={passwordworldnoor}
								onInput={(e) => {
									setPasswordworldnoor(e.target.value);
								}}
								required
								onChange={PasswordChange}>
								<div slot="content-end" className="cursor-pointer">
									{passwordvalue === "password" ? (
										<Eye
											onClick={() => setPasswordValue("text")}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									) : (
										<EyeSlash
											onClick={() => setPasswordValue("password")}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									)}
								</div>
								<div slot="media">
									<Lock color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
						</List>
						<List>
							<ListItem className="">
								<Button
									// type="submit"
									fill
									className="w-full"
									onClick={LoginWithPosh}>
									{t("Login to WN Social")}
								</Button>
							</ListItem>
						</List>
						<List>
							<ListItem className="mt-1 pl-1">
								<div className=" w-full flex justify-center">
									<p className="text-[16px]  text-[--f7-body-text]">
										<span className="opacity-70"> {t("Or")} </span>
										<Link className="text-[--f7-md-primary]" href="/signup">
											{" "}
											{t("Create an Account")}
										</Link>
									</p>
								</div>
							</ListItem>
							<ListItem>
								<p className="font-normal text-[13px] leading-[19.5px] tracking-[0.35px] font-[Poppins]">
									{t(
										"WN Social values your privacy and security.By logging into KT Messenger through WN Social, rest assured that your information and data are treated with the utmost confidentiality. Our commitment to your privacy means that your data will not be shared or compromised."
									)}
								</p>
							</ListItem>
						</List>
					</div>
				</PageContent>
			</Popup>
		</Page>
	);
};

export default LoginPage;
