import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Page,
	Button,
	Navbar,
	NavLeft,
	NavRight,
	f7,
	PageContent,
	useStore,
} from "framework7-react";
import userimage from "../../assets/images/placeholders/user.png";
import "./style.css";
import { ENDPOINTS, LISTENERS } from "../../constants/socket";
import { SocketContext } from "../../socket";
import {
	Video,
	VideoSlash,
	Microphone2,
	MicrophoneSlash,
	Call,
	Message,
} from "iconsax-react";
import io from "socket.io-client";
import RtcHelpers from "webrtc-client/src/rtc_helpers";
import SfuRtcClient from "webrtc-client/src/sfu/sfu_rtc_client";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../js/db";
import CallTimer from "../../components/call/CallTimer";
import beepSfx from "../../assets/sounds/calling.mp3";
import LazyLoaderWraper from "../misc/lazyloaderWraper";
import { useTranslation } from "react-i18next";
import { CALL_SOCKET_URL } from "../../constants";

const Callscreen = ({ chatId, mode, direction }) => {
	const getCurrentTime = () => {
		const now = new Date();
		const hours = String(now.getHours()).padStart(2, "0");
		const minutes = String(now.getMinutes()).padStart(2, "0");
		const seconds = String(now.getSeconds()).padStart(2, "0");
		const milliseconds = String(now.getMilliseconds()).padStart(3, "0");
		return `${hours}:${minutes}:${seconds}.${milliseconds}`;
	};
	const callChatId = useStore("callChatId");
	const isCallStarted = useStore("isCallStarted");
	const [micMuted, setMicMuted] = useState(false);
	const audio = new Audio(beepSfx);
	audio.loop = true;
	audio.autoplay = false;
	const deviceInfo = f7.device;
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const callState = useStore("callState");
	const [roomId, setRoomId] = useState(null);
	const [rtcClient, setRtcClient] = useState(null);
	const [isClicked, setIsClicked] = useState(false);
	const [groupCall, setGroupCall] = useState("idle");
	const [participants, setParticipants] = useState({});
	const [participantsIds, setParticipantsIds] = useState([]);
	const [callStatus, setCallStatus] = useState("");
	const [audioStreams, setAudioStreams] = useState({});
	const [videoStreams, setVideoStreams] = useState({});
	const [screeStreams, setScreenStreams] = useState({});
	const [localVideoStream, setLocalVideoStream] = useState(null);
	const localVideoRef = useRef();
	const audioRefs = useRef({});
	const videoRefs = useRef({});
	const screenRefs = useRef({});
	let media_server_socket = null;

	const callType = useStore("callType");
	const callDirection = useStore("callDirection");
	const callChatType = useStore("callChatType");
	const callRoomId = useStore("callRoomId");
	const { t, i18n } = useTranslation();

	const { loginResponse } = useSelector((state) => state.loginSlice);
	useEffect(() => {

		for (const userId in audioStreams) {
			if (audioRefs.current[userId] && audioStreams[userId]) {
				audioRefs.current[userId].srcObject = audioStreams[userId];
			}
		}
		for (const userId in videoStreams) {
			if (videoRefs.current[userId] && videoRefs[userId]) {
				videoRefs.current[userId].srcObject = videoRefs[userId];
			}
		}
	}, [audioStreams, videoStreams]);
	const chat = useLiveQuery(
		() => db.chats.where({ chat_id: callChatId }).first(),
		[callChatId],
		{}
	);
	const attachListners = () => {
		rtcClient.onCallStarted = onCallStarted;
		rtcClient.onInitPeer = onInitPeer;
		rtcClient.onLeft = onLeft;
		rtcClient.onJoined = onJoined;
		rtcClient.onPostJoinData = onPostJoinData;

		rtcClient.onUserVideoStream = onUserVideoStream;
		rtcClient.onUserVideoStreamEnded = onUserVideoStreamEnded;
		rtcClient.onVideoSwitched = onVideoSwitched;
		rtcClient.onPotentialUserVideo = onPotentialUserVideo;

		rtcClient.onUserAudioStream = onUserAudioStream;
		rtcClient.onUserAudioStreamEnded = onUserAudioStreamEnded;
		rtcClient.onPotentialUserAudio = onPotentialUserAudio;

		rtcClient.onReconnecting = onReconnecting;
		rtcClient.onDisconnected = onDisconnected;
		rtcClient.onReconnected = onReconnected;
		rtcClient.onReconnectionFailed = onReconnectionFailed;
		rtcClient.onLocalUserVideoStream = onLocalUserVideoStream;
	};
	useEffect(() => {
		if (rtcClient != null) {
			attachListners();
			const participants = rtcClient.participants;
			if (Object.keys(participants).length > 0) {
				stopOutgoingSound();
			}
		}

		f7.on(LISTENERS.CALL_ENDED, (response) => {
			if (response.chatId == callChatId) {
				const remainingMembers = participantList.filter(
					(member) => member.id !== response.connectedUserId
				);
				if (remainingMembers.length == 0 && callChatType == "single") {
					endCall();
				}
			}
		});
		return () => {
			f7.off(LISTENERS.CALL_ENDED);
		};
	}, [rtcClient]);
	useEffect(() => {
		f7.on(LISTENERS.NEW_CALL_RECEIVED, (response) => {
			if (response.chatId == callChatId && direction == "outgoing") {
				setCallStatus("Ringing...");
				audio.play();
			}
		});
		f7.on(LISTENERS.CALL_ANSWERED, (response) => {
			if (response.chatId == callChatId && direction == "outgoing") {
				setCallStatus("Connecting...");
				//stop audio
				audio.pause();
				audio.currentTime = 0;
			}
		});
		f7.on(LISTENERS.CALL_ENDED, (response) => {
			audio.pause();
			audio.currentTime = 0;
			if (response.chatId == callChatId) {
				const remainingMembers = participantList.filter(
					(member) => member.id !== response.connectedUserId
				);
				if (remainingMembers.length == 0 && callChatType == "single") {
					endCall();
				}
			}
		});

		return () => {
			f7.off(LISTENERS.NEW_CALL_RECEIVED);
			f7.off(LISTENERS.CALL_ANSWERED);
			f7.off(LISTENERS.CALL_ENDED);
			audio.pause();
			audio.currentTime = 0;
		};
	}, []);

	useEffect(() => {
		if (isCallStarted == false) {
			if (callChatId > 0) {
				if (direction == "outgoing") {
					/*if (isRunning) {
						time = new Date();
						time.setSeconds(time.getSeconds() + 45);
						restart(time, true);
					} else {
						start();
					}
					play();
					*/

					socket.emit(
						ENDPOINTS.START_NEW_CALL,
						JSON.stringify({
							chatId: callChatId,
							isVideo: callType == "video" ? 1 : 0,
						}),
						async (response) => {
							f7.store.dispatch("setIsCallStarted", true);
							if (response.roomId) {
								setRoomId(response.setRoomId);
								setUpRTCClient(response.roomId);
								setCallStatus("Calling..");
								///playOutgoingSound();
							}
						}
					);
				} else {
					setCallStatus("");
					f7.store.dispatch("setIsCallStarted", true);
					setRoomId(callRoomId);
					setUpRTCClient(callRoomId);
				}
			}
		} else {
			if (rtcClient && rtcClient.have_joined == false) {
				joinCall();
			}
		}
	}, [direction, isCallStarted]);

	const playOutgoingSound = (params) => {
	};
	const closeOutGoingCall = (params) => {

		stopOutgoingSound();
	};
	const stopOutgoingSound = (params) => {
		audio.pause();
		audio.currentTime = 0;
	};
	const onReconnecting = (params) => {
	};
	const onDisconnected = (params) => {
	};
	const onReconnected = (params) => {
	};
	const onReconnectionFailed = (params) => {
	};
	const onLocalUserVideoStream = (params) => {
		const { stream, track, user_id } = params;
		if (stream) {
			setLocalVideoStream(stream);
			if (localVideoRef.current) {
				localVideoRef.current.srcObject = stream;
			}
		}
	};
	const onUserVideoStream = (params) => {
		const { stream, user_id } = params;
		if (user_id == loginResponse.data.id) {
			if (stream) {
				setLocalVideoStream(stream);
				if (localVideoRef.current) {
					localVideoRef.current.srcObject = stream;
				}
			}
		}
		setVideoStreams((prevStreams) => ({
			...prevStreams,
			[user_id]: stream,
		}));
	};
	const onUserVideoStreamEnded = (params) => {
		var { user_id } = params;
		setVideoStreams((prevStreams) => {
			const updatedStreams = { ...prevStreams };
			delete updatedStreams[user_id];
			return updatedStreams;
		});
	};
	const onVideoSwitched = (params) => {
		const { is_video_on, user_id } = params;
		if (is_video_on) {
			addToUserVideoStreams(params);
			getUserVideoStream(params);
		}
	};
	const onPotentialUserVideo = (params) => {
		const { stream, user_id } = params;
		addToUserVideoStreams(params);
		getUserVideoStream(params);
	};
	const getUserVideoStream = (params) => {
		const { user_id } = params;
		rtcClient.getUserVideoStream({ user_id });
	};
	const addToUserVideoStreams = (params) => {
		var { stream, user_id, is_waiting, participant } = params;
		setVideoStreams((prevStreams) => ({
			...prevStreams,
			[user_id]: stream,
		}));
	};

	const endCall = () => {
		socket.emit(
			ENDPOINTS.END_CALL,
			JSON.stringify({
				chatId: callChatId,
				manually: 1,
			})
		);
		if (rtcClient != null) {
			rtcClient.leaveCall({ join_main_room: false });
			rtcClient.endCall();
		}
		setTimeout(() => {
			f7.store.dispatch("resetCall");
			f7.popup.close(".callscreen-popup");
			f7.popup.close(".incoming-call-popup");
			setRtcClient(null);
		}, 300);
	};
	const muteLocalVideo = () => {
		rtcClient.turnOffVideo();
		setLocalVideoStream(null);
	};
	const unMuteLocalVideo = async () => {
		rtcClient.turnOnVideo();
	};
	const toggleLocalVideo = () => {
		if (localVideoStream != null) {
			muteLocalVideo();
		} else {
			unMuteLocalVideo();
		}
	};

	const toggleLocalAudio = () => {
		setMicMuted((muted) => !muted);
		if (audioStreams[loginResponse.data.id] != null) {
			rtcClient.switchUserAudio({
				target_user_id: loginResponse.data.id,
				is_audio_on: false,
			});
		} else {
			rtcClient.notifyTheseUsers({
				event: "request-turn-on-user-audio",
				user_ids: [loginResponse.data.id],
			});
		}
	};

	const setUpRTCClient = async (room_id) => {
		media_server_socket = io(
			CALL_SOCKET_URL + "?user_id=" + loginResponse.data.id,
			{
				transports: ["websocket"],
			}
		);
		media_server_socket.on("connect", () => {
		});

		media_server_socket.on("disconnect", () => {
		});
		const userObj = loginResponse.data;
		window.rtc_helpers = new RtcHelpers({
			socket: media_server_socket,
			userObj,
		});
		const rtc_client = await initiateLibrary(room_id);
		return rtc_client;
	};

	useEffect(() => {
		return () => {
			media_server_socket?.disconnect();
		};
	}, []);

	const initiateLibrary = async (room_id) => {
		const userObj = {
			...loginResponse.data,
			name: loginResponse.data.firstname,
			firstName: loginResponse.data.firstname,
			profile_pic: loginResponse.data.original_image,
			profile_picture: loginResponse.data.original_image,
			profile_picture_url: loginResponse.data.original_image,
		};
		const rtc_client = new SfuRtcClient();
		rtc_client.room = {
			id: room_id,
			user_id: loginResponse.data.id,
			title: "Meet Now",
			description: "",
		};
		rtc_client.room_id = room_id;
		rtc_client.user = userObj;
		rtc_client.socket = media_server_socket;
		rtc_client.socket_io = io;
		rtc_client.bandwidth = {
			upload: {
				audio: {
					max_per_track: 0,
				},
			},
		};

		rtc_client.rtc_config = {
			iceServers: [
				{
					url: "stun:stun.worldnoordev.com:3478",
					urls: "stun:stun.worldnoordev.com:3478",
				},
				{
					url: "turn:turn.worldnoordev.com:3478",
					username: "softech",
					urls: "turn:turn.worldnoordev.com:3478",
					credential: "Kalaam2020",
				},
			],
		};

		rtc_client.user_media_constraints = {
			video: {
				width: { max: 640 },
				height: { max: 480 },
				frameRate: { max: 24 },
				aspectRatio: 1.77778,
			},
			audio: {
				echoCancellation: true,
				noiseSuppression: true,
			},
		};

		rtc_client.display_media_constraints = {
			video: {
				width: { max: 1920 },
				height: { max: 1080 },
				frameRate: { max: 15 },
				aspectRatio: 1.77778,
			},
		};
		if (callType == "audio") {
			rtc_client.initial_user_media_constraints.video = false;
		}
		setRtcClient(rtc_client);
		return rtc_client;
	};

	const joinCall = async () => {
		/*var stream = await rtcClient.getInitialMedia();
		console.log("joinCall stream", stream);
		if (stream && stream.getVideoTracks().length) {
			console.log("joinCall video stream", stream);
			rtcClient.stream = stream;
			setLocalVideoStream(stream);
			if (localVideoRef.current) {
				localVideoRef.current.srcObject = stream;
			}
		}*/
		rtcClient.joinCall();
	};
	const onInitPeer = (data) => {
	};
	const haveLeftCall = (params) => {
		//endCall();
	};
	const updateParticipantsData = (params) => {
		const rtcParticipants = rtcClient.participants;
		const participants_ids = rtcClient.participants_ids;
		if (!rtcParticipants) return;
		setParticipants(rtcParticipants);
		setParticipantsIds(participants_ids);
	};

	const onLeft = (params) => {
		var { user_id, participants_ids } = params;
		rtcClient.dropUserAudioStream({ user_id });
		if (user_id == loginResponse.data.id) {
			haveLeftCall();
		} else {
			updateParticipantsData();
		}
	};

	const onUserAudioStream = (params) => {
		var { stream, user_id } = params;
		handleAudioStreamUpdate(user_id, stream);
	};
	const handleAudioStreamUpdate = (userId, stream) => {
		setAudioStreams((prevStreams) => ({
			...prevStreams,
			[userId]: stream,
		}));
	};
	const handleAudioStreamRemove = (userId) => {
		setAudioStreams((prevStreams) => {
			const updatedStreams = { ...prevStreams };
			delete updatedStreams[userId];
			return updatedStreams;
		});
	};

	const onUserAudioStreamEnded = (params) => {
		var { user_id } = params;
		handleAudioStreamRemove(user_id);
	};
	const onPotentialUserAudio = (params) => {
		var { user_id } = params;
		rtcClient.getUserAudioStream({ user_id });
	};
	const onPostJoinData = (params) => {
		var { user_id } = params;
	};

	const onJoined = (params) => {
		var { user_id } = params;

		const callParticipants = rtcClient.participants;
		const callparticipants_ids = rtcClient.participants_ids;
		if (!callParticipants) return;
		setParticipants(callParticipants);
		setParticipantsIds(callparticipants_ids);
		if (Object.values(participants).length > 1) {
			setCallStatus("");
		}
		if (user_id == loginResponse.data.id) {
			callparticipants_ids.forEach((user_id) => {
				rtcClient.getUserAudioStream({ user_id });
				const person = callParticipants[user_id];
				if (person && person.is_video_on) {
					rtcClient.getUserVideoStream({ user_id });
				}
			});
		}
	};

	const getUserStreams = () => {
		participantsIds.forEach((user_id) => {
			rtcClient.getUserAudioStream({ user_id });
		});
	};
	const onCallStarted = async (data) => {
	};

	const RenderVideoElement = (item) => {
		return (
			<video
				key={item.userId}
				autoPlay
				playsInline
				className="h-full w-full object-cover rounded-[15px]"
				ref={(video) => {
					if (
						video != null &&
						videoStreams[item.userId] &&
						videoStreams[item.userId].id
					) {
						video.srcObject = videoStreams[item.userId];
					}
				}}></video>
		);
	};
	const getBorderColor = (index) => {
		const colors = [
			"kt-pink",
			"kt-orange",
			"kt-yellow",
			"kt-green",
			"kt-blue",
			"kt-purple",
		];
		return colors[index % colors.length]; // Use modulo operator to loop through colors array
	};
	const RenderParticipent = (item) => {
		const participant = item.participant;
		const borderColor = getBorderColor(item.index);
		let basis;
		if (participantList.length < 2) {
			basis = "";
		} else if (participantList.length <= 3) {
			basis = "basis-1/" + participantList.length;
		} else if (participantList.length > 3) {
			basis = "basis-1/3";
		}

		return (
			<div
				className={`relative participant-box min-w-[320px] max-h-full gap-[10px] border-[3px] border-solid border-${borderColor}
  ${participantList.length === 1 ? "max-w-full" : "max-w-[320px] h-[386px]"} 
  ${
		(item.index + 1) % 3 === 0
			? "basis-full"
			: participantList.length === 1
			? ""
			: `basis-1/${participantList.length}`
	}`}>
				{videoStreams[participant.id] ? (
					<RenderVideoElement userId={participant.id} />
				) : (
					<LazyLoaderWraper
						src={
							participant.user.profile_pic
								? participant.user.profile_pic
								: userimage
						}
						placeholder={userimage}
						height={80}
						width={80}
						alt=""
						className="rounded-full object-cover"
					/>
				)}
				<span
					className={`text-base text-white ${
						videoStreams[participant.id] ? "absolute bottom-5" : ""
					}`}>
					{participant.user.name}
				</span>
			</div>
		);
	};
	const participantList = Object.values(participants)?.filter(
		(member) => member.id !== loginResponse.data.id
	);
	if (participantList.length > 0) {
		stopOutgoingSound();
	}

	let gridTemplate;
	if (participantList.length === 1) {
		gridTemplate = "1fr / 1fr"; // Full screen
	} else if (participantList.length === 2) {
		gridTemplate = "1fr / repeat(2, 1fr)"; // Two boxes half screen
	} else if (participantList.length <= 4) {
		gridTemplate = "repeat(2, 1fr) / repeat(2, 1fr)"; // Four boxes 2 top 2 bottom
	} else {
		gridTemplate = "repeat(3, 1fr) / repeat(3, 1fr)";
	}

	return (
		<Page id="callscreen" name="callscreen" pageContent={false}>
			<Navbar transparent>
				<NavLeft transparent className="ml-[20px]">
					<LazyLoaderWraper
						src={
							!chat?.hide_profile_picture
								? chat?.image
									? chat?.image
									: chat?.profile_image
								: userimage
						}
						placeholder={userimage}
						height={45}
						width={45}
						alt=""
						className="rounded-full align-bottom"
						wrapperclassname="rounded-full align-bottom"
					/>
					<div className="flex flex-col justify-center items-start ml-[10px]">
						<span
							className={`text-secondary text-base font-medium text-ellipsis ${
								participantList.length !== 0 ? "text-white" : "text-body"
							} max-w-[335px]`}>
							{chat?.nickname ? chat?.nickname : chat?.firstname}
							<br />
						</span>
						{participantList.length > 0 && <CallTimer />}
					</div>
				</NavLeft>
				<NavRight className="mr-[20px]">
					<Button
						popupClose=".callscreen-popup"
						rounded
						fill
						className="w-[169px] h-[40px] p-5 gap-0">
						<Message size="20" color="#ffffff" />
						<span className="font-semibold text-base items-center leading-6 pl-[5px]">
							{t("Return to chat")}
						</span>
					</Button>
				</NavRight>
			</Navbar>
			<PageContent
				className={`flex flex-col justify-around items-center pb-0 ${
					participantList.length !== 0 ? "bg-[#414247]" : ""
				}`}>
				{participantList.length > 0 && (
					<div
						className={`participants-grid flex items-center justify-center p-[20px] gap-[20px] max-height-[832px] ${
							participantList.length < 2 ? "single" : "group"
						} ${participantList.length >= 3 ? "flex-wrap" : ""}`}>
						{Object.values(participants).map(
							(participant, index) =>
								participant.id != loginResponse.data.id && (
									<RenderParticipent
										participant={participant}
										key={participant.id}
										index={index}
									/>
								)
						)}
					</div>
				)}

				{participantList.length === 0 && (
					<div className="w-full h-full flex flex-col items-center justify-center p-[20px] gap-[15px] max-height-[832px]">
						<LazyLoaderWraper
							src={
								participantList.length === 0
									? !chat?.hide_profile_picture
										? chat?.image
											? chat?.image
											: chat?.profile_image
										: userimage
									: userimage
							}
							placeholder={userimage}
							height={300}
							width={300}
							alt=""
							wrapperclassname="rounded-full object-cover"
							className="rounded-full object-cover"
						/>
						<div className="font-bold text-[32px] text-body leading-[42.65px] text-center flex justify-center">
							{chat?.nickname
								? chat?.nickname
								: chat?.firstname + " " + chat?.lastname}
						</div>
						<div className="font-bold text-[16px] text-body leading-[21.82px] text-center flex justify-center">
							{callStatus || ""}
						</div>
					</div>
				)}

				<div
					className="user-audio-streams-container"
					style={{ position: "absolute", visibility: "hidden" }}>
					{Object.keys(audioStreams).map(
						(userId) =>
							userId != loginResponse.data.id && (
								<audio
									key={userId}
									controls
									autoPlay
									playsInline
									ref={(audio) => {
										audioRefs.current[userId] = audio;
									}}>
									<source src="" type="audio/mpeg" />
									{t("Your browser does not support the audio element.")}
								</audio>
							)
					)}
				</div>

				{localVideoStream && (
					<div className="localvideo w-[222px] absolute rounded-[15px] overflow-hidden">
						<video
							className="local-video-stream"
							ref={(video) => {
								if (video != null && localVideoStream && localVideoStream.id) {
									video.srcObject = localVideoStream;
								}
							}}
							autoPlay
							playsInline
							id={localVideoStream && localVideoStream.id}
							muted={true}></video>
					</div>
				)}

				{participantList.length === 0 ? (
					<div className="fixed bottom-0 flex justify-center m-auto w-[335px] h-[80px] gap-[25px] mb-[41px]">
						<Button
							onClick={endCall}
							rounded
							fill
							className="w-[60px] h-[60px] bg-[#e05047]">
							<Call
								size="20"
								color="#FFFFFF"
								variant="Bold"
								className="mt-[4px] rotate-[135deg]"
							/>
						</Button>
						<Button
							rounded
							fill
							className="w-[60px] h-[60px] bg-[#BBBBBC]/50"
							popupClose=".callscreen-popup">
							<Message size="20" color="#ffffff" variant="Bold" />
						</Button>
					</div>
				) : (
					<div className="fixed bottom-0 flex justify-center w-[335px] h-[80px] bg-[#BBBBBC]/50 p-[10px] rounded-full gap-[25px] mb-[10px]">
						<Button
							onClick={toggleLocalVideo}
							rounded
							fill
							className={`w-[60px] h-[60px] ${
								!localVideoStream ? "bg-[#BBBBBC]/50" : "bg-white"
							}`}>
							{localVideoStream != null ? (
								<Video size="20" color="#302F36" variant="Bold" />
							) : (
								<VideoSlash size="20" color="#FFFFFF" variant="Bold" />
							)}
						</Button>
						<Button
							onClick={toggleLocalAudio}
							rounded
							fill
							className={`w-[60px] h-[60px] ${
								micMuted ? "bg-[#BBBBBC]/50" : "bg-white"
							}`}>
							{!micMuted ? (
								<Microphone2 size="20" color="#302F36" variant="Bold" />
							) : (
								<MicrophoneSlash size="20" color="#FFFFFF" variant="Bold" />
							)}
						</Button>
						{/*<Button
                rounded
                fill
                className="w-[60px] h-[60px] mr-[20px]"
                style={{ backgroundColor: "#BBBBBC" }}
              >
                <VolumeMute size="20" color="#ffffff" />
              </Button>
                */}
						<Button
							onClick={endCall}
							rounded
							fill
							className="w-[60px] h-[60px] bg-[#e05047]">
							<Call
								size="20"
								color="#FFFFFF"
								variant="Bold"
								className="mt-[4px] rotate-[135deg]"
							/>
						</Button>
					</div>
				)}
			</PageContent>
		</Page>
	);
};
//export default React.memo(Callscreen);
export default Callscreen;
